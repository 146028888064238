// frontend/src/components/channel-select.tsx
import React from 'react';
import { Form } from 'react-bootstrap';
import { useContentCreatorChannels } from '~/hooks/use-content-creator-channels';
import { useChannelStore } from '~/stores/channel-store';

const ChannelSelect: React.FC = () => {
    const { data: channels } = useContentCreatorChannels();
    const { currentChannel, setCurrentChannel } = useChannelStore();

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setCurrentChannel(event.target.value);
    };

    return (
        <Form.Group className="mb-3" controlId="formChannel">
            <Form.Label>My Channel</Form.Label>
            <Form.Select
                value={currentChannel}
                onChange={handleChange}
            >
                <option value="">Select a channel</option>
                {channels && channels.map((channel) => (
                    <option key={channel.channelId} value={channel.channelId}>
                        {channel.channelId}
                    </option>
                ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
                Channel handle is required.
            </Form.Control.Feedback>
        </Form.Group>
    );
};

export default ChannelSelect;