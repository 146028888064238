import { useMutation, UseMutationResult } from 'react-query';

interface UploadData {
    channel: string;
    file: File;
    previewImage?: File;
    title: string;
    description: string;
}

export interface UploadError extends Error {
    response?: Response;
}

const useUploadVideo = (onProgress: (progress: number) => void): UseMutationResult<any, UploadError, UploadData> => {
    return useMutation(async ({ channel, file, previewImage, title, description }: UploadData) => {
        const formData = new FormData();
        formData.append('channelId', channel);
        formData.append('video', file);
        {previewImage && formData.append('previewImage', previewImage)}
        formData.append('title', title);
        formData.append('description', description);

        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', `/api/content-creator/${channel}/videos/upload`, true);

            xhr.upload.onprogress = (event) => {
                if (event.lengthComputable) {
                    const progress = (event.loaded / event.total) * 100;
                    onProgress(progress);
                }
            };

            xhr.onload = () => {
                if (xhr.status >= 200 && xhr.status < 300) {
                    resolve(JSON.parse(xhr.responseText));
                } else {
                    const error: UploadError = new Error('Network response was not ok');
                    error.response = new Response(xhr.responseText, {
                        status: xhr.status,
                        statusText: xhr.statusText,
                    });
                    reject(error);
                }
            };

            xhr.onerror = () => {
                const error: UploadError = new Error('Network error');
                reject(error);
            };

            xhr.send(formData);
        });
    });
};

export default useUploadVideo;