import { useQuery } from 'react-query';

const fetchVideos = async (channel: string) => {
    const response = await fetch(`/api/content-creator/${channel}/videos/list`);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

export const useVideos = (channel: string) => {
    return useQuery(['video-list', channel], () => fetchVideos(channel), {
        enabled: !!channel  // Only run the query if the channel parameter is available
    });
};
