import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import Header from './header';
import VideoPlayer from './video-player';
import PlaylistLinks from './playlist-links';
import styles from './course-player.module.css';
import { Footer } from '../layout';
import useVideoProgressStore from './stores/video-progress-store';
import { useGetLastWatchedVideo, useSaveLastWatchedVideo } from './hooks/use-video-progress';
import PdfViewer from './pdf-viewer';
import Content from "./types/content";
import ContentDescription from "./content-description";

function getContentInfo(links: Array<Content>, content: string) {
    return links.find((link) => link.path === content);
}

const CoursePlayer: React.FC = () => {
    const { channelId = '', courseId = '', videoId = '' } = useParams<{ channelId: string; courseId: string; videoId?: string }>();
    const navigate = useNavigate();
    const [courseData, setCourseData] = useState<any>(null);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const { isAuthenticated, isLoading } = useAuth();
    const {setVideoProgress} = useVideoProgressStore({channelId, courseId});
    const { data: lastWatchedVideo, isLoading: isLastWatchedLoading } = useGetLastWatchedVideo();
    const { mutate: saveLastWatchedVideo } = useSaveLastWatchedVideo();

    useEffect(() => {
        if (channelId && courseId) {
            fetch(`/course-info/${channelId}/${courseId}.json`, {
                headers: {
                    'Cache-Control': 'no-cache'
                }
            })
                .then(response => response.json())
                .then(setCourseData)
                .catch(console.error);
        }
    }, [channelId, courseId]);

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            fetch(`/api/user/authorized/${channelId}/${courseId}/`, {
                credentials: 'include',
                headers: { 'Cache-Control': 'no-cache' }
            })
                .then(response => response.json())
                .then(data => setIsAuthorized(data.isAuthorized))
                .catch(console.error);
        } else if (!isLoading) {
            navigate(`/login?channel=${channelId}&course=${courseId}`);
        }
    }, [isAuthenticated, isLoading, channelId, courseId, navigate]);

    useEffect(() => {
        document.documentElement.setAttribute('data-bs-theme', 'dark');
        return () => document.documentElement.removeAttribute('data-bs-theme');
    }, []);

    useEffect(() => {
        if (courseData && !isLastWatchedLoading) {
            if (!videoId) {
                if (lastWatchedVideo) {
                    navigate(`/player/${channelId}/${courseId}/${lastWatchedVideo}`, { replace: true });
                } else {
                    navigate(`/player/${channelId}/${courseId}/${courseData.playList[0].path}`, { replace: true });
                }
            } else if (courseData && videoId) {
                const currentVideo = courseData.playList.find((v: any) => v.path === videoId);
                if (currentVideo) {
                    document.title = `${courseData.title} - ${currentVideo.title}`;
                    saveLastWatchedVideo({ channel: channelId, course: courseId, video: videoId });
                }
            }
        }
    }, [channelId, courseId, videoId, courseData, lastWatchedVideo, isLastWatchedLoading, navigate, saveLastWatchedVideo]);

    const handleVideoEnd = ({ totalLength, progress }: { totalLength: number, progress: number }) => {
        if (courseData && videoId) {

            setVideoProgress({ videoId, progress, totalLength });

            const currentIndex = courseData.playList.findIndex((v: any) => v.path === videoId);
            const nextIndex = currentIndex + 1;
            if (nextIndex < courseData.playList.length) {
                const nextVideo = courseData.playList[nextIndex].path;
                setVideoProgress({videoId: nextVideo, totalLength: 0, progress: 0});
                navigate(`/player/${channelId}/${courseId}/${nextVideo}`, { replace: true });
            }
        }
    };

    if (isLoading || isLastWatchedLoading) return <div>Loading...</div>;

    const contentInfo = getContentInfo(courseData.playList, videoId);
    const currentIndex = courseData?.playList.findIndex((v: any) => v.path === videoId);
    const nextVideo = currentIndex !== undefined && currentIndex + 1 < courseData.playList.length
        ? courseData.playList[currentIndex + 1].path
        : undefined;

    return (
        <section className={styles.coursePage}>
            {courseData && <Header channel={channelId} />}
            <main>
                {courseData && (
                    <section className={styles.player}>
                        {isAuthorized ? (
                            <>
                                <div className={styles.playerWrapper}>
                                    {contentInfo && ['video', 'audio'].includes(contentInfo.type) &&
                                        <VideoPlayer channelId={channelId}
                                                     courseId={courseId}
                                                     videoId={videoId}
                                                     onEnd={handleVideoEnd}/>
                                    }
                                    {contentInfo && contentInfo.type === 'pdf' &&
                                        <PdfViewer channel={channelId}
                                                   course={courseId}
                                                   file={videoId}
                                                   nextVideo={nextVideo}/>
                                    }
                                    {contentInfo && <ContentDescription content={contentInfo} />}

                                </div>
                                <PlaylistLinks links={courseData.playList}
                                               title={courseData.title}
                                               channelId={channelId}
                                               courseId={courseId}
                                               videoId={videoId} />
                            </>
                        ) : (
                            <p>Please wait...</p>
                        )}
                    </section>
                )}
            </main>
            <Footer />
        </section>
    );
};

export default CoursePlayer;