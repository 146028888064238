import React from 'react';
import { Button } from 'react-bootstrap';
import ResponsiveImage from '../components/responsive-image';
import styles from './library-card.module.css';
import Course from '~/types/course';
import LockedResponsiveImage from './locked-responsive-image';

const buttonText: { [key: string]: string } = {
    course: "View Course",
    game: "Play Game",
    default: "View"
};

interface LibraryItemCardProps {
    course: Course;
    linkToSalesPage?: boolean;
    hideLock: boolean;
    className?: string;
}

function getUrl(params: { course: Course, linkToSalesPage?: boolean }) {
    const { course, linkToSalesPage } = params;
    const { url, channelId, courseId, isParent } = course;

    let libraryUrl = url || `${channelId}/${courseId}`;

    if (!url) {
        if (linkToSalesPage) {
            libraryUrl = `/course/${libraryUrl}`;
        } else {
            if (isParent) {
                libraryUrl = `/channel/${libraryUrl}`;
            } else {
                libraryUrl = course.isEnrolled ? `/player/${libraryUrl}` : `/course/${libraryUrl}`;
            }
        }
    }
    return libraryUrl;
}

export const LibraryCard: React.FC<LibraryItemCardProps> = ({ course, linkToSalesPage, hideLock, className }) => {
    const { type, title, channelId, courseId } = course;
    const libraryUrl = getUrl({ course, linkToSalesPage });
    const { isEnrolled, isParent, enrollAll } = course;

    const getImage = () => {
        if (!isEnrolled && !hideLock && !isParent && !enrollAll) {
            return <LockedResponsiveImage
                className={styles.libraryCardImage}
                basePath={`/course-info/${channelId}`}
                src={courseId}
                alt={`Image Title ${title}`} />
        }

        if (hideLock || isEnrolled || isParent || enrollAll) {
            return <ResponsiveImage
                className={styles.libraryCardImage}
                basePath={`/course-info/${channelId}`}
                src={courseId}
                alt={`Image Title ${title}`} />
        }
        return null;
    }

    return (
        <section className={`${styles.libraryCardItem} ${className}`}>
            <a href={libraryUrl}>
                {getImage()}
            </a>
            <div>
                <h3>{title}</h3>
                <Button className={styles.libraryCardButton}
                        href={libraryUrl} variant="outline-primary">
                    {buttonText[type || 'default']}
                </Button>
            </div>
        </section>
    );
};

interface LibraryItemListProps {
    courses: Course[];
    linkToSalesPage?: boolean;
    hideLock?: boolean;
}

export const LibraryCardList: React.FC<LibraryItemListProps> = ({courses, linkToSalesPage, hideLock = false }) => {
    return (
        <section className={styles.libraryItemsList}>
            {courses.map((course: Course, index: number) => (
                <LibraryCard
                    key={course.courseId}
                    course={course}
                    linkToSalesPage={linkToSalesPage}
                    hideLock={hideLock}
                    className={index === 0 ? `${styles.libraryCardItem} ${styles.firstItem}` : styles.libraryCardItem}
                />
            ))}
        </section>
    );
};

export default LibraryCard;