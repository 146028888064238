import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useChannelStore } from '~/stores/channel-store';
import styles from './main.module.css';
import headerLogoStyles from '~/header-logo.module.css';
import LoginButton from './components/login-button';
import ResponsiveImage from './components/responsive-image';

interface SimpleLayoutProps {
    headerText?: string;
    linkToCatalog?: boolean;
}

function getHeaderLink(channelId: string | undefined, linkToCatalog: boolean) {
    if (linkToCatalog && channelId) {
        return `/catalog/${channelId}`;
    } else {
        return `/`;
    }
}

export const ChannelStoreLayout: React.FC<SimpleLayoutProps> = ({ headerText, linkToCatalog }) => {
    const { currentChannel: channelId } = useChannelStore();
    const headerLink = getHeaderLink(channelId, !!linkToCatalog);

    return (
        <>
            <header className={styles.simpleLayoutHeader}>
                <nav className={styles.simpleLayoutHeaderTextWrapper}>
                    {!channelId && <Link to="/">
                        <ResponsiveImage className={styles.logo}
                                         alt="Renew You"
                                         basePath="/images"
                                         src="renewyou-logo" />
                    </Link>}
                    {channelId && <Link to={headerLink}>
                        <img className={headerLogoStyles.headerLogo}
                             alt={`${channelId} Logo `}
                             src={`/course-info/${channelId}/channel-logo-light.png`} />
                    </Link>}
                    {headerText && <h1 className={styles.headerText}>{headerText}</h1>}
                </nav>
                <LoginButton />
            </header>
            <main>
                <Outlet />
            </main>
        </>
    );
};