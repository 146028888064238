// frontend/src/store/channelStore.ts
import create from 'zustand';

interface ChannelState {
    currentChannel: string;
    setCurrentChannel: (channel: string) => void;
}

const getCurrentChannelFromLocalStorage = (): string => {
    const storedChannel = localStorage.getItem('currentChannel');
    return storedChannel ? JSON.parse(storedChannel) : '';
};

export const useChannelStore = create<ChannelState>((set) => ({
    currentChannel: getCurrentChannelFromLocalStorage(),
    setCurrentChannel: (channel: string) => {
        localStorage.setItem('currentChannel', JSON.stringify(channel));
        set({ currentChannel: channel });
    },
}));