import { useQuery } from "react-query";

const fetchImages = async (channelId: string) => {
    const response = await fetch(`/api/content-creator/${channelId}/images/list`);
    if (!response.ok) throw new Error("Failed to fetch images");
    return response.json();
};

export const useFetchImages = (channelId: string) => {
    return useQuery(["images", channelId], () => fetchImages(channelId), {
        enabled: !!channelId,
    });
};