import ResponsiveImage from "~/components/responsive-image";
import styles from "./home.module.css";

export default function Home() {
    return (
        <>
            <div className={styles.content}>
                <div className={styles.leftMain}>
                    <h1 className={styles.heading}>Your <br/>Gateway to Wellness<br/> Mastery</h1>
                    <p className={styles.info}>The most secure streaming platform for Health and Wellness course
                        creators.</p>

                </div>
                <div className={styles.rightMain}>
                    <ResponsiveImage alt="Photo of a woman singing in front of a teacher on a TV."
                                     className={styles.mainImage}
                                     basePath="images"
                                     src="sing"/>
                </div>
            </div>
        </>
    );
}