import React from 'react';
import { LibraryCardList } from '../components/library-card';
import { Link } from 'react-router-dom';
import { useChildCourses } from '~/course-list/hooks/use-child-courses';
import styles from "./course-list-page.module.css";

const CourseListPage: React.FC = () => {
    const { data, isLoading, error } = useChildCourses();

    const courses = data?.courses ?? []; // Default to empty array if undefined
    const title = data?.title ?? ''; // Default to empty string if undefined

    if (isLoading) {
        return <div style={{ textAlign: 'center', marginTop: '5rem' }}>Loading...</div>;
    }

    if (error) {
        return <div style={{ textAlign: 'center', marginTop: '5rem' }}>Error loading courses</div>;
    }

    if (courses.length === 0) {
        return (
            <div>
                <p style={{ textAlign: 'center', marginTop: '5rem' }}>
                    You have not enrolled in any courses. Try viewing the <Link to="/catalog">course catalog</Link>.
                </p>
            </div>
        );
    }

    return (
        <section className={styles.main}>
            <h1>{title}</h1>
            <LibraryCardList courses={courses} />
        </section>
    );
};

export default CourseListPage;
